import React from 'react';
import { graphql, PageRendererProps } from 'gatsby';
import Layout from '../components/Layout/Layout';
import { OperationTypeListingPageQuery } from '../../gatsby-graphql';
import PropertiesListingDiscovery from '../components/PropertiesListingDiscovery/PropertiesListingDiscovery';

interface IOperationTypeListingTemplateProps extends PageRendererProps {
  data: OperationTypeListingPageQuery;
  pageContext: {
    operationTypeSlug: string;
    operationTypeListingName: string;
    propertyLocationName: string;
    propertyLocationSlug: string;
  };
}

const LocalizedOperationTypeListingTemplate: React.FC<IOperationTypeListingTemplateProps> = ({
  data: {
    navigation,
    footer,
    properties,
    propertyLocations,
    propertiesListingConfig,
    propertySpecs,
  },
  pageContext: {
    operationTypeListingName,
    propertyLocationName,
    propertyLocationSlug,
  },
}) => (
  <Layout {...{ navigation, footer }}>
    <PropertiesListingDiscovery
      {...{
        propertyLocations,
        operationTypeListingName,
        propertyLocationName,
      }}
      localizedFilters
      propertiesListingConfig={propertiesListingConfig.nodes[0]}
      properties={properties.nodes}
      propertySpecs={propertySpecs.nodes}
      totalProperties={properties.totalCount}
      activePropertyLocationType={{
        label: propertyLocationName,
        value: propertyLocationSlug,
      }}
      propertyTypeName="Propiedad"
    />
  </Layout>
);

export const pageQuery = graphql`
  query LocalizedOperationTypeListingPage(
    $operationTypeId: String!
    $propertyLocationId: String!
  ) {
    ...SiteNavigation
    ...PageListingFilters
    properties: allContentfulProperty(
      sort: { fields: [createdAt], order: [DESC] }
      filter: {
        operationType: { id: { eq: $operationTypeId } }
        propertyLocation: { id: { eq: $propertyLocationId } }
      }
    ) {
      totalCount
      nodes {
        ...PropertyCard
      }
    }
  }
`;

export default LocalizedOperationTypeListingTemplate;
